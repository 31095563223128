<template>
  <div class="myform mt-4">
    <b-container fluid v-if="step1">
      <b-row align-h="center">
        <b-col sm="12" lg="6">
          <b-card>
            <b-card-text>
              <h3 style="text-align: center">
                Medicare Supplement Application
              </h3>
              <h5><b>Your Selected Plan</b></h5>
              <p><b>Company:</b> {{company}} - Plan {{plan}}<br>
              <b>Amount</b> ${{amnt}}</p>
              <p style="text-align: left !important">
                To complete this Medicare Supplement application Please fill in
                each field and click the button below to continue. *Note this is
                page one of two.
              </p>
              <b-form @submit.stop.prevent="onSubmit">
                <!-- Contact Info -->
                <h6 style="color: #3f51b5"><b>Contact Info</b></h6>
                <b-form-row class="mt-2">
                  <b-col>
                    <label for="first_name">First Name:</label>
                    <b-form-input
                      id="first_name"
                      ref="first_name"
                      v-model="$v.contact.first_name.$model"
                      :state="validateState('first_name')"
                      aria-describedby="first_name-help first_name-feedback"
                      trim
                    ></b-form-input>
                    <b-form-invalid-feedback id="first_name-live-feedback"
                      >This is a required field</b-form-invalid-feedback
                    >
                  </b-col>
                </b-form-row>
                <b-form-row class="mt-2">
                  <b-col>
                    <label for="first_name">Last Name:</label>
                    <b-form-input
                      id="last_name"
                      ref="last_name"
                      v-model="$v.contact.last_name.$model"
                      :state="validateState('last_name')"
                      aria-describedby="last_name-help last_name-feedback"
                      trim
                    ></b-form-input>
                    <b-form-invalid-feedback id="last_name-live-feedback"
                      >This is a required field</b-form-invalid-feedback
                    >
                  </b-col>
                </b-form-row>
                <b-form-row class="mt-2">
                  <b-col>
                    <label for="phone1">Phone1:</label>
                    <b-form-input
                      id="phone1"
                      ref="phone1"
                      v-model="$v.contact.phone1.$model"
                      :state="validateState('phone1')"
                      aria-describedby="phone1-help phone1-feedback"
                      trim
                      v-mask="'(###)###-####'"
                    ></b-form-input>
                    <b-form-invalid-feedback id="phone1-live-feedback"
                      >This is a required field</b-form-invalid-feedback
                    >
                  </b-col>
                </b-form-row>
                <b-form-row class="mt-2">
                  <b-col>
                    <label for="email">Email:</label>
                    <b-form-input
                      id="email"
                      ref="email"
                      v-model="$v.contact.email.$model"
                      :state="validateState('email')"
                      aria-describedby="email-help email-feedback"
                      trim
                    ></b-form-input>
                    <b-form-invalid-feedback id="email-live-feedback"
                      >This is a required field</b-form-invalid-feedback
                    >
                  </b-col>
                </b-form-row>

                <!-- Residential Address -->
                <h6 style="color: #3f51b5" class="mt-3">
                  <b>Residential Address</b>
                </h6>
                <b-form-row class="mt-2">
                  <b-col>
                    <label for="address">Address:</label>
                    <b-form-input
                      id="address"
                      ref="address"
                      v-model="$v.contact.address.$model"
                      :state="validateState('address')"
                      aria-describedby="address-help address-feedback"
                      trim
                    ></b-form-input>
                    <b-form-invalid-feedback id="address-live-feedback"
                      >This is a required field</b-form-invalid-feedback
                    >
                  </b-col>
                </b-form-row>
                <b-form-row class="mt-2">
                  <b-col>
                    <label for="city">City:</label>
                    <b-form-input
                      id="city"
                      ref="city"
                      v-model="$v.contact.city.$model"
                      :state="validateState('city')"
                      aria-describedby="city-help city-feedback"
                      trim
                    ></b-form-input>
                    <b-form-invalid-feedback id="city-live-feedback"
                      >This is a required field</b-form-invalid-feedback
                    >
                  </b-col>
                </b-form-row>
                <b-row class="mt-2">
                  <b-col>
                    <label for="example-input-1">State:</label>
                    <b-form-select
                      size="sm"
                      id="state"
                      name="state"
                      ref="state"
                      :options="states"
                      v-model="$v.contact.state.$model"
                      :state="validateState('state')"
                      aria-describedby="input-1-live-feedback"
                    ></b-form-select>

                    <b-form-invalid-feedback id="input-1-live-feedback"
                      >This is a required field</b-form-invalid-feedback
                    >
                  </b-col>
                </b-row>
                <b-form-row class="mt-2">
                  <b-col>
                    <label for="zip">Zip Code:</label>
                    <b-form-input
                      id="zip"
                      ref="zip"
                      v-model="$v.contact.zip.$model"
                      :state="validateState('zip')"
                      aria-describedby="zip-help zip-feedback"
                      trim
                    ></b-form-input>
                    <b-form-invalid-feedback id="zip-live-feedback"
                      >This is a required field</b-form-invalid-feedback
                    >
                  </b-col>
                </b-form-row>

                <!--Mailing Address -->
                <b-row>
                  <b-col>
                    <h6 style="color: #3f51b5" class="mt-3">
                      <b>Mailing Address</b>
                    </h6>
                  </b-col>
                  <b-col>
                    <b-button
                      variant="outline-primary"
                      class="mt-3"
                      size="sm"
                      @click="same_address"
                      >Same As Above</b-button
                    >
                  </b-col>
                </b-row>
                <b-form-row class="mt-2">
                  <b-col>
                    <label for="address">Mailing Address:</label>
                    <b-form-input
                      id="mailing_address"
                      ref="mailing_address"
                      v-model="$v.contact.mailing_address.$model"
                      :state="validateState('mailing_address')"
                      aria-describedby="mailing_address-help mailing_address-feedback"
                      trim
                    ></b-form-input>
                    <b-form-invalid-feedback id="mailing_address-live-feedback"
                      >This is a required field</b-form-invalid-feedback
                    >
                  </b-col>
                </b-form-row>
                <b-form-row class="mt-2">
                  <b-col>
                    <label for="mailing_city">Mailing City:</label>
                    <b-form-input
                      id="mailing_city"
                      ref="mailing_city"
                      v-model="$v.contact.mailing_city.$model"
                      :state="validateState('mailing_city')"
                      aria-describedby="mailing_city-help mailing_city-feedback"
                      trim
                    ></b-form-input>
                    <b-form-invalid-feedback id="mailing_city-live-feedback"
                      >This is a required field</b-form-invalid-feedback
                    >
                  </b-col>
                </b-form-row>
                <b-row class="mt-2">
                  <b-col>
                    <label for="example-input-2">Mailing State:</label>
                    <b-form-select
                      size="sm"
                      id="mailing_state"
                      ref="mailing_state"
                      name="mailing_state"
                      :options="states"
                      v-model="$v.contact.mailing_state.$model"
                      :state="validateState('state')"
                      aria-describedby="input-2-live-feedback"
                    ></b-form-select>

                    <b-form-invalid-feedback id="input-1-live-feedback"
                      >This is a required field</b-form-invalid-feedback
                    >
                  </b-col>
                </b-row>
                <b-form-row class="mt-2">
                  <b-col>
                    <label for="mailing_zip">Mailing Zip Code:</label>
                    <b-form-input
                      id="mailing_zip"
                      ref="mailing_zip"
                      v-model="$v.contact.mailing_zip.$model"
                      :state="validateState('mailing_zip')"
                      aria-describedby="mailing_zip-help mailing_zip-feedback"
                      trim
                      v-mask="'#####'"
                    ></b-form-input>
                    <b-form-invalid-feedback id="mailing_zip-live-feedback"
                      >This is a required field</b-form-invalid-feedback
                    >
                  </b-col>
                </b-form-row>

                <!-- Personal Details -->
                <h6 style="color: #3f51b5" class="mt-3">
                  <b>Personal Details</b>
                </h6>
                <b-form-row class="mt-2">
                  <b-col>
                    <label for="date_of_birth"
                      >Date Of Birth (MM-DD-YYYY):</label
                    >
                    <b-form-input
                      id="date_of_birth"
                      ref="date_of_birth"
                      v-model="$v.contact.date_of_birth.$model"
                      :state="validateState('date_of_birth')"
                      aria-describedby="date_of_birth-help date_of_birth-feedback"
                      trim
                      v-mask="'##-##-####'"
                    ></b-form-input>
                    <b-form-invalid-feedback id="date_of_birth-live-feedback"
                      >This is a required field</b-form-invalid-feedback
                    >
                  </b-col>
                </b-form-row>
                <b-row class="mt-2">
                  <b-col>
                    <label for="gender">Gender:</label>
                    <b-form-select
                      size="sm"
                      id="gender"
                      name="gender"
                      ref="gender"
                      :options="genders"
                      v-model="$v.contact.gender.$model"
                      :state="validateState('gender')"
                      aria-describedby="gender-live-feedback"
                    ></b-form-select>

                    <b-form-invalid-feedback id="gender-live-feedback"
                      >This is a required field</b-form-invalid-feedback
                    >
                  </b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col>
                    <label for="tobacco_user">Tobacco User:</label>
                    <b-form-select
                      size="sm"
                      id="tobacco_user"
                      name="tobacco_user"
                      ref="tobacco_user"
                      :options="tobaccos"
                      v-model="$v.contact.tobacco_user.$model"
                      :state="validateState('tobacco_user')"
                      aria-describedby="tobacco_user-live-feedback"
                    ></b-form-select>

                    <b-form-invalid-feedback id="tobacco_user-live-feedback"
                      >This is a required field</b-form-invalid-feedback
                    >
                  </b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col>
                    <label for="contact_height">Height:</label>
                    <b-form-select
                      size="sm"
                      id="contact_height"
                      name="contact_height"
                      ref="contact_height"
                      :options="heights"
                      v-model="$v.contact.contact_height.$model"
                      :state="validateState('contact_height')"
                      aria-describedby="contact_height-live-feedback"
                    ></b-form-select>

                    <b-form-invalid-feedback id="contact_height-live-feedback"
                      >This is a required field</b-form-invalid-feedback
                    >
                  </b-col>
                </b-row>
                <b-form-row class="mt-2">
                  <b-col>
                    <label for="weight">Weight:</label>
                    <b-form-input
                      id="weight"
                      ref="weight"
                      v-model="$v.contact.weight.$model"
                      :state="validateState('weight')"
                      aria-describedby="weight-help weight-feedback"
                      trim
                      v-mask="'### lbs'"
                    ></b-form-input>
                    <b-form-invalid-feedback id="weight-live-feedback"
                      >This is a required field</b-form-invalid-feedback
                    >
                  </b-col>
                </b-form-row>
                <b-row class="mt-2">
                  <b-col>
                    <label for="state_of_birth">State Of Birth:</label>
                    <b-form-select
                      size="sm"
                      id="state_of_birth"
                      name="state_of_birth"
                      ref="state_of_birth"
                      :options="states"
                      v-model="$v.contact.state_of_birth.$model"
                      :state="validateState('state_of_birth')"
                      aria-describedby="state_of_birth-live-feedback"
                    ></b-form-select>

                    <b-form-invalid-feedback id="state_of_birth-live-feedback"
                      >This is a required field</b-form-invalid-feedback
                    >
                  </b-col>
                </b-row>
                <b-form-row class="mt-2">
                  <b-col>
                    <label for="ssn">SSN:</label>
                    <b-form-input
                      id="ssn"
                      ref="ssn"
                      v-model="$v.contact.ssn.$model"
                      :state="validateState('ssn')"
                      aria-describedby="ssn-help ssn-feedback"
                      trim
                      v-mask="'###-##-####'"
                    ></b-form-input>
                    <b-form-invalid-feedback id="ssn-live-feedback"
                      >This is a required field</b-form-invalid-feedback
                    >
                  </b-col>
                </b-form-row>

                <!-- Medicare Details -->
                <h6 style="color: #3f51b5" class="mt-3">
                  <b>Medicare Details</b>
                </h6>
                <b-form-row class="mt-2">
                  <b-col>
                    <label for="medicare_number">Medicare Number:</label>
                    <b-form-input
                      id="medicare_number"
                      ref="medicare_number"
                      v-model="$v.contact.medicare_number.$model"
                      :state="validateState('medicare_number')"
                      aria-describedby="medicare_number-help medicare_number-feedback"
                      trim
                      v-mask="'NNNN-NNN-NNNN'"
                    ></b-form-input>
                    <b-form-invalid-feedback id="medicare_number-live-feedback"
                      >This is a required field</b-form-invalid-feedback
                    >
                  </b-col>
                </b-form-row>

                <b-row class="mt-2">
                  <b-col>
                    <label for="part_a_eff_date"
                      >Part A Eff. Date (MM-DD-YYYY):</label
                    >
                    <b-form-input
                      id="part_a_eff_date"
                      ref="part_a_eff_date"
                      v-model="$v.contact.part_a_eff_date.$model"
                      :state="validateState('part_a_eff_date')"
                      aria-describedby="part_a_eff_date-help part_a_eff_date-feedback"
                      trim
                      v-mask="'##-##-####'"
                    ></b-form-input>
                    <b-form-invalid-feedback id="part_a_eff_date-live-feedback"
                      >This is a required field</b-form-invalid-feedback
                    >
                  </b-col>
                </b-row>

                <b-form-row class="mt-2">
                  <b-col>
                    <label for="part_b_eff_date"
                      >Part B Eff. Date (MM-DD-YYYY):</label
                    >
                    <b-form-input
                      id="part_b_eff_date"
                      ref="part_b_eff_date"
                      v-model="$v.contact.part_b_eff_date.$model"
                      :state="validateState('part_b_eff_date')"
                      aria-describedby="part_b_eff_date-help part_b_eff_date-feedback"
                      trim
                      v-mask="'##-##-####'"
                    ></b-form-input>
                    <b-form-invalid-feedback id="part_b_eff_date-live-feedback"
                      >This is a required field</b-form-invalid-feedback
                    >
                  </b-col>
                </b-form-row>
                <b-button
                  class="my-3"
                  style="background-color: #1e5191 !important"
                  size="md"
                  type="submit"
                  variant="primary"
                  >Next Step</b-button
                >
              </b-form>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <!-- STEP 2 -->
    <b-container fluid v-if="step2">
      <b-row align-h="center">
        <b-col sm="12" lg="6">
          <b-card>
            <b-card-text>
              <div id="steptwotop" ref="steptwotop"></div>
              <h3 style="text-align: center">Finish Your Application</h3>
              <h6 style="color: #3f51b5">
                <b>A Few Final Important Questions</b>
              </h6>
              <b-form @submit.stop.prevent="onSubmit2">
                <b-row class="mt-2">
                  <b-col>
                    <label for="open_enrollment"
                      >Are You Eligible For Open Enrollment:</label
                    >
                    <b-form-select
                      size="sm"
                      id="open_enrollment"
                      name="open_enrollment"
                      :options="droplist1"
                      v-model="$v.cal_event.open_enrollment.$model"
                      :state="validateState2('open_enrollment')"
                      aria-describedby="open_enrollment-live-feedback" ref="open_enrollment"
                    ></b-form-select>

                    <b-form-invalid-feedback id="open_enrollment-live-feedback"
                      >This is a required field</b-form-invalid-feedback
                    >
                  </b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col>
                    <label for="open_enrollment"
                      >Are You Eligible For Guaranteed Issue:</label
                    >
                    <b-form-select
                      size="sm"
                      id="guaranteed_issue"
                      name="guaranteed_issue"
                      :options="droplist1"
                      v-model="$v.cal_event.guaranteed_issue.$model"
                      :state="validateState2('guaranteed_issue')"
                      aria-describedby="guaranteed_issue-live-feedback" ref="guaranteed_issue"
                    ></b-form-select>

                    <b-form-invalid-feedback id="guaranteed_issue-live-feedback"
                      >This is a required field</b-form-invalid-feedback
                    >
                  </b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col>
                    <label for="open_enrollment"
                      >Are You Married Or In A Civil Union Partnership:</label
                    >
                    <b-form-select
                      size="sm"
                      id="married"
                      name="married"
                      :options="droplist2"
                      v-model="$v.cal_event.married.$model"
                      :state="validateState2('married')"
                      aria-describedby="married-live-feedback" ref="married"
                    ></b-form-select>

                    <b-form-invalid-feedback id="married-live-feedback"
                      >This is a required field</b-form-invalid-feedback
                    >
                  </b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col>
                    <label for="lived"
                      >Have You Lived With An Adult For The Last 12
                      Months:</label
                    >
                    <b-form-select
                      size="sm"
                      id="lived"
                      name="lived"
                      :options="droplist2"
                      v-model="$v.cal_event.lived.$model"
                      :state="validateState2('lived')"
                      aria-describedby="lived-live-feedback" ref="lived"
                    ></b-form-select>

                    <b-form-invalid-feedback id="lived-live-feedback"
                      >This is a required field</b-form-invalid-feedback
                    >
                  </b-col>
                </b-row>
                <h6 style="color: #3f51b5" class="mt-3">
                  <b>Contact Availability</b>
                </h6>
                <p>
                  Please indicate the best time that you can be reached should
                  we need to contact you.
                </p>
                <b-row class="mt-2">
                  <b-col>
                    <label for="best_phone">Best Contact Number For You:</label>
                    <b-form-input
                      id="best_phone"
                      v-model="cal_event.best_phone"
                      :state="validateState2('best_phone')"
                      aria-describedby="best_phone-help best_phone-feedback"
                      v-mask="'(###)###-####'" ref="best_phone"
                    ></b-form-input>
                    <b-form-invalid-feedback id="best_phone-live-feedback"
                      >This is a required field</b-form-invalid-feedback
                    >
                  </b-col>
                </b-row>
                <b-form-row class="mt-2">
                  <b-col>
                    <label for="date">Date:</label>
                    <b-form-datepicker
                      id="date"
                      v-model="$v.cal_event.date.$model"
                      :state="validateState2('date')"
                      aria-describedby="date-help date-feedback"
                      trim ref="date"
                    ></b-form-datepicker>
                    <b-form-invalid-feedback id="date-live-feedback"
                      >This is a required field</b-form-invalid-feedback
                    >
                  </b-col>
                </b-form-row>
                <b-form-row class="mt-2">
                  <b-col>
                    <label for="time">Time:</label>
                    <b-form-timepicker
                      id="time"
                      v-model="$v.cal_event.time.$model"
                      :state="validateState2('time')"
                      aria-describedby="time-help time-feedback"
                      trim ref="time"
                    ></b-form-timepicker>
                    <b-form-invalid-feedback id="time-live-feedback"
                      >This is a required field</b-form-invalid-feedback
                    >
                  </b-col>
                </b-form-row>
                <b-button
                  class="my-3"
                  style="background-color: #1e5191 !important"
                  size="md"
                  type="submit"
                  variant="primary"
                  >Finish</b-button
                >
              </b-form>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <!-- STEP 3 -->
    <b-container fluid v-if="step3">
      <b-row align-h="center">
        <b-col sm="12" lg="6">
          <b-card>
            <b-card-text>
              <h3>Thank You For Your Submission</h3>
              <p>Please be on the lookout for a call from 512-687-1476</p>
              <p>
                You can view our disclaimer(s) and other policies by clicking
                <b-link  @click="modal_show = true"
                  >here</b-link
                >
              </p>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <b-modal v-model="modal_show" size="lg">
      <h2>Disclaimer Notices</h2>

      <div class="col-md-12">
        <div class="col-md-8">
          <h5>
            MedicareMall.com Provides Free Information on health insurance and
            other health coverage options. Some products and services may not be
            available in all states, and no assurances of the same are made
            herein.
          </h5>

          <h5>
            Important Information when you are applying for Health Insurance
          </h5>

          <p style="font-size: 14px">
            If you are currently covered by Health Insurance, DO NOT CANCEL YOUR
            POLICY. Also, DO NOT DECLINE COBRA BENEFITS until you have received
            an approval letter and/or the insurance policy (sometimes referred
            to as the insurance contract or the insurance certificate) from the
            insurance company you have selected with our agency.
          </p>

          <p style="font-size: 14px">
            It is IMPORTANT that you UNDERSTAND and AGREE with the terms,
            limitations, riders, and exclusions of your insurance policy. Give
            SPECIAL ATTENTION to the policy's effective date, premium amount,
            waiting period, and other important provisions.
          </p>

          <h3>Underwriting Disclaimers</h3>

          <p style="font-size: 14px">
            Quotes, prices and/or rates displayed on our Web site are only
            estimates. Premium figures and coverage are subject to change based
            on many factors that include, but are not limited to, your medical
            history, the insurance company's underwriting practices, optional
            benefits you select, and other relevant factors. The insurance
            company determines your actual policy premium amount and reserves
            the right to change the terms of a policy upon proper notification.
            Additionally, there is NO expressed or implied guarantee that you
            will be offered health insurance coverage. Check with the insurance
            company about any HIPPA provisions that may affect your access to
            coverage.
          </p>

          <p style="font-size: 14px">
            Quotes, prices and/or rates displayed are ONLY for the requested
            effective date. A different effective date for the policy may affect
            the actual premium of your policy. Any change in price may be due to
            many factors such as premium increase, policy changes from the
            insurance company, or one of more family members having a birthday
            (rates are very age sensitive). The insurance company you have
            chosen may not guarantee their rates for any period of time.
          </p>

          <p style="font-size: 14px">
            When you are issued a policy, this does not guarantee benefits.
            Availability of benefits is subject to eligibility and other terms,
            limitations, and exclusions of the policy. This includes, but is not
            limited to, the pre-existing waiting period. Deductibles,
            co-payments, and co-insurance amounts are your share of the costs
            for covered benefits and are subject to change. Each insurance
            company may have unique notices, disclaimers, and fees related to
            the policies they issue.
          </p>

          <p style="font-size: 14px">
            Some insurance companies may require dependent children to apply
            separately when they reach the age of majority or at another given
            age. These limits for dependents can vary by insurance company and
            by a state's mandates wherein the insured resides. Some companies
            may not extend the age limit for dependents even in the event they
            are full-time students at an accredited college, university, or
            other institution of higher education.
          </p>

          <h3>Privacy Policy</h3>

          <p style="font-size: 14px">
            MedicareMall.com is committed to protecting your privacy. We may
            provide your personal information or e-mail address to outside third
            parties to help facilitate your request for health insurance quotes
            or in an effort to apply for a health insurance policy. Disclosure
            of confidential information may also occur in the event that it is
            required to help law enforcement or to comply with a court order
            demand.
          </p>

          <p style="font-size: 14px">
            To better protect your privacy we have adopted the following
            Internet privacy policy. This privacy policy tells you, among other
            things, what information we gather from you, how we may use or
            disclose the information, and our efforts to protect this
            information. Please read this policy carefully and feel free to
            contact us if you have any inquiries, complaints, or questions
            regarding its contents.
          </p>

          <h3>Information We May Collect</h3>

          <p style="font-size: 14px">
            This notice applies to all information collected or submitted on the
            www.MedicareMall.com Web site and information we may obtain from you
            in writing or over the phone.
          </p>

          <p style="font-size: 14px">
            We may collect your personal information from quote request forms,
            applications, or over the phone. Sample types of personal
            information collected are:
          </p>

          <ul>
            <li>Name</li>
            <li>Date of Birth</li>
            <li>Address</li>
            <li>Personal Habits or Hobbies</li>
            <li>Email Address</li>
            <li>Phone Numbers</li>
            <li>Social Security Number</li>
            <li>Banking Relationships</li>
            <li>Family members names and dates of birth</li>
            <li>Current health status and medical history</li>
          </ul>

          <h3>The Way We Use Information</h3>

          <p style="font-size: 14px">
            As permitted by law, we may disclose personal information (listed
            above) to health insurance companies, affiliates, or licensed agents
            for the purposes of providing the health insurance products or
            services you have requested. We do not share this information with
            outside parties except to the extent necessary to complete a plan
            quote, an application for health insurance, or for other coverage
            services.
          </p>

          <p style="font-size: 14px">
            We use return email addresses to answer the emails we receive. Such
            addresses are only used for the pursuit of health insurance
            products. By sharing your e-mail address with us, you agree to
            receive notifications about rates changes, insurance policy quotes,
            and health plan information and to correspond with licensed agents
            about such matters. Your e-mail address will remain confidential and
            will not be given out to any third parties for other purposes.
          </p>

          <p style="font-size: 14px">
            We use non-identifying and aggregate information to better design
            our Web site. For example, we may tell a marketing company that “X”
            number of individuals visited a certain area of our Web site, but we
            would not disclose anything that could be used to identify those
            individuals.
          </p>

          <p style="font-size: 14px">
            Finally, we never use or share the personally identifiable
            information provided to us online in ways unrelated to the ones
            described above without also providing you an opportunity to opt-out
            or otherwise prohibit such unrelated uses.
          </p>

          <h3>Security Procedures</h3>

          <p style="font-size: 14px">
            We restrict access to your personal information by those employees
            or agents whom we have determined to be engaged in illegitimate
            business purposes and only allow access to such information in
            connection with the provision of providing products and services to
            you. We maintain physical, electronic, and procedural safeguards
            that comply with federal regulations to guard your personal
            information.
          </p>

          <h3>Children's Privacy</h3>

          <p style="font-size: 14px">
            In compliance with the Children's Online Privacy Protection Act
            (COPPA), the www.MedicareMall.com Web site is not directed at
            children under the age of 15 and does not knowingly collect personal
            data from children under the age of 15 without the consent of their
            legal guardian. If we receive actual knowledge that we have such
            personal information without the required verifiable consent, we
            will permanently delete the information from our database as soon as
            practically possible.
          </p>

          <h3>Policy Changes and Effective Date</h3>

          <p style="font-size: 14px">
            This Privacy Policy is effective as of May 2017. Our privacy and
            security policies are reviewed periodically. Any changes to this
            policy will be posted on our Web site so users are always aware of
            how we use the information we collect.
          </p>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { validationMixin } from "vuelidate";
import { required, minLength, email } from "vuelidate/lib/validators";

export default {
  name: "Form",
  props: {
    msg: String,
  },
  mixins: [validationMixin],
  data() {
    return {
      modal_show: false,
      is_saving: false,
      step1: true,
      step2: false,
      step3: false,
      contact_id: 0,
      company: "",
      amnt: "",
      plan: "",
      scid: 0,
      contact: {
        first_name: "",
        last_name: "",
        phone1: "",
        email: "",
        address: "",
        city: "",
        state: "",
        zip: "",
        mailing_address: "",
        mailing_city: "",
        mailing_state: "",
        mailing_zip: "",
        age: "",
        gender: "",
        contact_height: "",
        date_of_birth: "",
        weight: "",
        tobacco_user: "",
        state_of_birth: "",
        ssn: "",
        medicare_number: "",
        part_a_eff_date: "",
        part_b_eff_date: "",
      },
      cal_event: {
        date: "",
        time: "",
        best_phone: "",
        open_enrollment: "",
        guaranteed_issue: "",
        married: "",
        lived: "",
      },
      droplist1: ["No", "Yes", "Not Sure"],
      droplist2: ["No", "Yes"],
      agent: 0,
      genders: [
        { text: "Male", value: "Male" },
        { text: "Female", value: "Female" },
      ],
      tobaccos: [
        { text: "Yes", value: "Yes" },
        { text: "No", value: "No" },
      ],
      states: [
        { text: "Select Your State", value: null, disabled: true },
        "AL",
        "AK",
        "AS",
        "AZ",
        "AR",
        "CA",
        "CO",
        "CT",
        "DE",
        "DC",
        "FM",
        "FL",
        "GA",
        "GU",
        "HI",
        "ID",
        "IL",
        "IN",
        "IA",
        "KS",
        "KY",
        "LA",
        "ME",
        "MH",
        "MD",
        "MA",
        "MI",
        "MN",
        "MS",
        "MO",
        "MT",
        "NE",
        "NV",
        "NH",
        "NJ",
        "NM",
        "NY",
        "NC",
        "ND",
        "MP",
        "OH",
        "OK",
        "OR",
        "PW",
        "PA",
        "PR",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VT",
        "VI",
        "VA",
        "WA",
        "WV",
        "WI",
        "WY",
      ],
      heights: [
        "4'5''",
        "4'6''",
        "4'7''",
        "4'8''",
        "4'9''",
        "4'10''",
        "4'11''",
        "5'0''",
        "5'1''",
        "5'2''",
        "5'3''",
        "5'4''",
        "5'5''",
        "5'6''",
        "5'7''",
        "5'8''",
        "5'9''",
        "5'10''",
        "5'11''",
        "6'0''",
        "6'1''",
        "6'2''",
        "6'3''",
        "6'4''",
        "6'5''",
        "6'6''",
        "6'7''",
        "6'8''",
        "6'9''",
        "6'10''",
        "6'11''",
        "7'0''",
      ],
    };
  },
  validations: {
    cal_event: {
      best_phone: { required, minLength: minLength(13) },
      date: { required },
      time: { required },
      open_enrollment: { required },
      guaranteed_issue: { required },
      married: { required },
      lived: { required },
    },
    contact: {
      first_name: { required },
      last_name: { required },
      phone1: { required, minLength: minLength(13) },
      email: { required, email },
      address: { required },
      city: { required },
      state: { required },
      zip: { required, minLength: minLength(5) },
      mailing_address: { required },
      mailing_city: { required },
      mailing_state: { required },
      mailing_zip: { required },
      date_of_birth: { required, minLength: minLength(10) },
      gender: { required },
      tobacco_user: { required },
      contact_height: { required },
      weight: { required },
      state_of_birth: { required },
      ssn: { required, minLength: minLength(11) },
      medicare_number: { required, minLength: minLength(13) },
      part_a_eff_date: { required },
      part_b_eff_date: { required },
    },
  },
  watch: {
    cal_event: {
      handler(val) {
        console.log(this.cal_event);
      },
      deep: true,
    },
  },
  created() {
    let urlParams = new URLSearchParams(window.location.search);
    //console.log(urlParams.get('contact_id'));
    this.contact_id = urlParams.get("contact_id");
    this.company = urlParams.get("company");
    this.amnt = urlParams.get("amnt");
    this.plan = urlParams.get("plan");

    axios
      .get(
        "https://ot1imo3lpd.execute-api.us-west-2.amazonaws.com/prod/webapi/contact_details/" +
          this.contact_id
      )
      .then((result) => {
        var element = result.data.contact.records[0];
        this.agent = element.created_by;
        this.contact.first_name = element.first_name;
        this.contact.last_name = element.last_name;
        this.contact.phone1 = element.phone1;
        this.contact.email = element.email;
        this.contact.gender = element.gender;
        this.contact.tobacco_user = element.tobacco_user;
        var dob = moment(element.date_of_birth).format("MM-DD-YYYY");
        // var dob = element.date_of_birth.split("-");
        // this.contact.date_of_birth = dob[1] + "-" + dob[2] + "-" + dob[0];
        this.contact.date_of_birth = dob;
        this.scid = result.data.sc.records[0].id;
      });
  },
  methods: {
    same_address() {
      this.contact.mailing_address = this.contact.address;
      this.contact.mailing_city = this.contact.city;
      this.contact.mailing_state = this.contact.state;
      this.contact.mailing_zip = this.contact.zip;
    },
    onSubmit() {
      this.$v["contact"].$touch();
      console.log(this.$v.$invalid)
      if (this.$v["contact"].$invalid) {
        // 1. Loop the keys
        for (let key in Object.keys(this.$v["contact"])) {
          // 2. Extract the input
          const input = Object.keys(this.$v["contact"])[key];
          // 3. Remove special properties
          if (input.includes("$")) return false;

          // 4. Check for errors
          if (this.$v["contact"][input].$error) {
            // 5. Focus the input with the error
            this.$refs[input].focus();

            // 6. Break out of the loop
            break;
          }
        }
      } else {
        this.step1 = false;
        this.step2 = true;
        this.$nextTick(() => {
          this.cal_event.best_phone = "";
        });
        this.cal_event.best_phone = "";
        window.scrollTo(0, 0) 
      }
    },
    // onSubmit() {
    //   this.$v.contact.$touch();
    //   if (this.$v.contact.$anyError) {
    //     console.log("Failed");
    //     return;
    //   }
    //   this.$v.cal_event.best_phone.$model = "";
    //   this.cal_event.best_phone = "";
    //   console.log(this.cal_event);
    //   this.step1 = false;
    //   this.step2 = true;
    // },
    save_enrollment() {
      this.is_saving = true;
      var params = {
        contact: this.contact,
        cal_event: this.cal_event,
        id: this.contact_id,
        company: this.company,
        amnt: this.amnt,
        plan: this.plan,
        scid: this.scid,
        agent: this.agent,
      };
      console.log(params);
      axios
        .post(
          "https://ot1imo3lpd.execute-api.us-west-2.amazonaws.com/prod/webapi/enroll_contact",
          params
        )
        .then((result) => {
          console.log(result);
          this.step2 = false;
          this.step3 = true;
        });
    },
    onSubmit2() {
      this.$v["cal_event"].$touch();
      console.log(this.$v.cal_event.$invalid)
      if (this.$v["cal_event"].$invalid) {
        // 1. Loop the keys
        for (let key in Object.keys(this.$v["cal_event"])) {
          // 2. Extract the input
          const input = Object.keys(this.$v["cal_event"])[key];
          console.log(input)
          // 3. Remove special properties
          if (input.includes("$")) return false;

          // 4. Check for errors
          if (this.$v["cal_event"][input].$error) {
            // 5. Focus the input with the error
            this.$refs[input].focus();

            // 6. Break out of the loop
            break;
          }
        }
      } else {
        this.save_enrollment();
        window.scrollTo(0, 0) 
      }
    },
    // onSubmit2() {
    //   this.$v.cal_event.$touch();
    //   if (this.$v.cal_event.$anyError) {
    //     console.log("Failed");
    //     return;
    //   }
    //   this.save_enrollment();
    // },
    validateState(name) {
      const { $dirty, $error } = this.$v.contact[name];
      return $dirty ? !$error : null;
    },
    validateState2(name) {
      const { $dirty, $error } = this.$v.cal_event[name];
      return $dirty ? !$error : null;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
label {
  font-size: 13px;
  font-weight: 600;
}
</style>
