<template>
  <div id="app">
    <b-container fluid>
      <b-row class="header-row" style="text-align: center!important"> 
      <b-col></b-col>
      <b-col cols="9">
        <img src="@/assets/mm_logo.png" alt="" />
      </b-col>
      <b-col></b-col>
    </b-row>
    </b-container>
    
    <b-container fluid>
      <b-row align-h="center">
        <b-col cols-sm="12" cols-lg="9">
          <Form />
        </b-col>
      </b-row>
    </b-container>
   </div>
</template>

<script>
import Form from "./components/Form.vue";

export default {
  name: "App",
  components: {
    Form,
  },
};
</script>

<style scoped>

.header-row {
  background-color: #1e5191!important;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
